import { SVGProps } from 'react';

export function ChubbSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 728.21 73.65"
      {...props}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M679.56 61.14h-77.27v-18.4h77.27Zm-77.27-48.57h77.27v17.87h-77.27Zm71.48 61.08c13.67 0 20-5.68 20-15.46v-8.62c0-2.73-1-4-2.94-5.68L682 36.32l8.84-7.56c2-1.69 2.94-3 2.94-5.68v-7.57c0-9.78-6.31-15.46-20-15.46h-85.37v73.6Zm-143.5-12.51H453v-18.4h77.27ZM453 12.57h77.27v17.87H453Zm71.49 61.08c13.67 0 20-5.68 20-15.46v-8.62c0-2.73-.94-4-2.94-5.68l-8.83-7.57 8.83-7.56c2-1.69 2.94-3 2.94-5.68v-7.57c0-9.78-6.3-15.46-20-15.46h-85.37v73.6ZM377.42.05V60.5h-73.07V.05h-13.88v56.77c0 10.41 6.31 16.83 18.82 16.83h63.19c12.51 0 18.81-6.42 18.81-16.83V.05ZM154.65 30.12V.05h-13.88v73.6h13.88v-30.6h74.11v30.6h13.88V.05h-13.88v30.07ZM100.29 13.2V.05H18.82C6.31.05 0 6.47 0 16.88v39.94c0 10.41 6.31 16.83 18.82 16.83h81.47V60.5h-86.1V13.2ZM722.14.77a9.66 9.66 0 0 1 3.18 2.13 9.94 9.94 0 0 1 2.12 3.19 10.17 10.17 0 0 1 .77 3.94 10.08 10.08 0 0 1-.77 4 9.75 9.75 0 0 1-2.12 3.17 10 10 0 0 1-3.18 2.12 10.59 10.59 0 0 1-7.93 0 10 10 0 0 1-3.21-2.17 9.75 9.75 0 0 1-2.09-3.15 10.08 10.08 0 0 1-.77-4 10.17 10.17 0 0 1 .77-3.94A9.94 9.94 0 0 1 711 2.9a9.66 9.66 0 0 1 3.21-2.13 10.59 10.59 0 0 1 7.93 0Zm-7.35 1.33a8.33 8.33 0 0 0-4.45 4.56 8.94 8.94 0 0 0-.63 3.37 8.9 8.9 0 0 0 .63 3.37 8.25 8.25 0 0 0 1.77 2.73 8.35 8.35 0 0 0 6.06 2.5 8.35 8.35 0 0 0 6-2.5A8.43 8.43 0 0 0 726 13.4a8.54 8.54 0 0 0 .65-3.37 8.58 8.58 0 0 0-.65-3.37 8.39 8.39 0 0 0-1.78-2.72 8.16 8.16 0 0 0-2.68-1.84 8.81 8.81 0 0 0-6.74 0Zm-.26 13.73V4.23H719a4.2 4.2 0 0 1 2.88.83 3 3 0 0 1 .9 2.38 3.5 3.5 0 0 1-.23 1.32 2.81 2.81 0 0 1-.63 1 3.09 3.09 0 0 1-.93.62 5.2 5.2 0 0 1-1.12.34l3.45 5.15h-1.67l-3.27-5.15H716v5.15Zm4.6-6.41a3.71 3.71 0 0 0 1.12-.22 1.74 1.74 0 0 0 .78-.61 1.94 1.94 0 0 0 .3-1.15 2 2 0 0 0-.23-1 1.7 1.7 0 0 0-.62-.6 2.49 2.49 0 0 0-.88-.31 5.55 5.55 0 0 0-1-.08H716v4h1.89c.39.02.82 0 1.24-.03Z"
            fill="#72CA00"
            className="cls-1"
          />
        </g>
      </g>
    </svg>
  );
}