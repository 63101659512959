import { Box, CircularProgress } from "@mui/material";

import CustomButton, {
  CustomButtonProps,
} from "components/common/CustomButton/CustomButton";

interface PaymentFooterProps {
  backButtonProps?: CustomButtonProps;
  submitButtonProps: CustomButtonProps;
  isLoading: boolean;
}

const PaymentFooter: React.FunctionComponent<PaymentFooterProps> = ({
  backButtonProps,
  submitButtonProps,
  isLoading,
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "end" }}>
      {backButtonProps && <CustomButton {...backButtonProps} />}
      <CustomButton
        data_cy="payment-footer-make-payment-btn"
        {...submitButtonProps}
        type="submit"
        btnText={
          isLoading ? <CircularProgress size={14} /> : submitButtonProps.btnText
        }
      />
    </Box>
  );
};

export default PaymentFooter;
