import { getPortalConfig } from "api/portalConfig";
import { IS_BROKER_PORTAL, IS_HOSTED_INSURANCE, PORTAL_NAME, PortalName } from "config";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPortalConfigError,
  setPortalConfigLoading,
  setPortalConfigSuccess,
} from "store/features/configSlice";
import { setCurentStep } from "store/features/stepperSlice";
import { RootState } from "store/store";

type Options = {
  clientName: string;
};

const appendCustomFont = (fontUrl: string): void => {
  const link = document.createElement("link");
  link.href = fontUrl;
  link.rel = "stylesheet";
  link.type = "text/css";
  document.head.appendChild(link);
};

function useSetupPortalConfig({ clientName }: Options) {
  const dispatch = useDispatch();
  const portalConfig = useSelector((state: RootState) => state.portalConfig);

  useEffect(() => {
    (async () => {
      if (IS_HOSTED_INSURANCE) {
        return;
      }
      if (portalConfig.data) return;
      dispatch(setPortalConfigLoading());
      try {
        const portalConfigData = await getPortalConfig(clientName, PortalName.LOCKTON === PORTAL_NAME);
        const { intermediary_portal_configuration } = portalConfigData.results;
        if (!portalConfigData.success) {
          return dispatch(
            setPortalConfigError(new Error("error fetching portal config"))
          );
        }
        dispatch(
          setCurentStep(
            IS_BROKER_PORTAL
              ? intermediary_portal_configuration.otherConfig.defaultCurrentStep
              : 0
          )
        );
        appendCustomFont(intermediary_portal_configuration.font.url);
        dispatch(setPortalConfigSuccess(intermediary_portal_configuration));
      } catch (e) {
        dispatch(setPortalConfigError(e));
      }
    })();
  }, [clientName, dispatch, portalConfig.data]);
}

export default useSetupPortalConfig;
