// Constants
export const ENVIRONMENT = process.env.REACT_APP_ENV;

export enum PortalName {
  CORX = "corx",
  FAIRFAX = "fairfax",
  LIBERSEGUROS = "liberseguros",
  ONCE = "once",
  HOSTED_INSURANCE = "hosted-insurance",
  LOCKTON = "lockton",
  LOCALHOST = "localhost", // for unit testing only
}

const brokerPortalPortalDomainNameMapping = {
  /* Uncomment stere.app urls once it is available */
  local: {
    [PortalName.CORX]: "sandbox.portal.corx.io",
    [PortalName.FAIRFAX]: "fairfax.br.sandbox.stere.io",
    [PortalName.LIBERSEGUROS]: "liberseguros.br.sandbox.stere.io",
  },
  sandbox: {
    [PortalName.CORX]: [
      "sandbox.portal.corx.io" /* , "sandbox.corx.stere.app" */,
    ],
    [PortalName.FAIRFAX]: "fairfax.br.sandbox.stere.io",
    [PortalName.LIBERSEGUROS]: "liberseguros.br.sandbox.stere.io",
  },
  production: {
    [PortalName.CORX]: ["portal.corx.io" /* , "corx.stere.app" */],
    [PortalName.FAIRFAX]: "fairfax.br.stere.io",
    [PortalName.LIBERSEGUROS]: "liberseguros.br.stere.io",
  },
};

export const FAIRFAX = PortalName.FAIRFAX;
export const LIBERSEGUROS = PortalName.LIBERSEGUROS;
export const CORX = PortalName.CORX;

export enum CountryCode {
  BR = "br",
  MX = "mx",
  PH = "ph",
  SG = "sg",
  US = "us",
}

export const CountryMapping: Record<CountryCode, string> = {
  br: "brazil",
  mx: "mexico",
  ph: "philippines",
  sg: "singapore",
  us: "usa",
};

const hostName = window.location.hostname;
const urlIdentifiers = hostName.split(".");

export const getPortalName = (): PortalName => {
  if (urlIdentifiers.includes(PortalName.CORX)) return PortalName.CORX;
  else if (urlIdentifiers.includes(PortalName.FAIRFAX))
    return PortalName.FAIRFAX;
  else if (urlIdentifiers.includes(PortalName.LIBERSEGUROS))
    return PortalName.LIBERSEGUROS;
  else if (urlIdentifiers.includes(PortalName.ONCE)) return PortalName.ONCE;
  else if (urlIdentifiers.includes(PortalName.HOSTED_INSURANCE))
    return PortalName.HOSTED_INSURANCE;
  else if (urlIdentifiers.includes(PortalName.LOCALHOST)) // for unit testing only
  return PortalName.CORX;
  else if (urlIdentifiers.includes(PortalName.LOCKTON))
    return PortalName.LOCKTON;
  else throw new Error("Client not supported");
};

const getCountryName = () => {
  const portalName = getPortalName();
  const portalCountryMap: Record<PortalName, CountryCode> = {
    [PortalName.CORX]: CountryCode.BR,
    [PortalName.FAIRFAX]: CountryCode.BR,
    [PortalName.LIBERSEGUROS]: CountryCode.BR,
    [PortalName.ONCE]: CountryCode.US,
    [PortalName.HOSTED_INSURANCE]: urlIdentifiers[1] as CountryCode,
    [PortalName.LOCKTON]: CountryCode.MX,
    [PortalName.LOCALHOST]: CountryCode.BR // for unit testing only
  };
  if (!portalCountryMap[portalName]) {
    throw new Error("Unable to get country name, Invalid portal type");
  }
  return portalCountryMap[portalName];
};

export const COUNTRY = getCountryName() as CountryCode;

export const PORTAL_NAME = getPortalName();

export const IS_HOSTED_INSURANCE = urlIdentifiers.includes(
  PortalName.HOSTED_INSURANCE
);

export const IS_BROKER_PORTAL = [
  PortalName.CORX,
  PortalName.FAIRFAX,
  PortalName.LIBERSEGUROS,
  PortalName.LOCKTON,
].includes(PORTAL_NAME);

console.log(
  "PORTAL_NAME",
  PORTAL_NAME,
  "COUNTRY",
  COUNTRY,
  "ENVIRONMENT",
  ENVIRONMENT
);

const getDomainName = () => {
  try {
    const brokerPortalDomainName =
      brokerPortalPortalDomainNameMapping[ENVIRONMENT][PORTAL_NAME];
    if (IS_BROKER_PORTAL && brokerPortalDomainName) {
      if (Array.isArray(brokerPortalDomainName)) {
        // if the hostname is in the list of domains, return the hostname
        let domainName = brokerPortalDomainName.find((domain) => hostName.includes(domain));
        if (domainName) {
          return domainName;
        }
        // return the first domain if the hostname is not in the list
        return brokerPortalDomainName[0];
      }
      return brokerPortalDomainName;
    }
    return window.location.hostname;
  } catch {
    return window.location.hostname;
  }
};

export const DOMAIN_NAME = getDomainName();

export const CLIENT_NAME = PORTAL_NAME;

export const isKnockoutEnabled = (product: string) => {
  switch (product) {
    case "PET":
      return COUNTRY === "mx" || COUNTRY === "sg";
    case "JWL":
      return COUNTRY === "us";
    case "DRONE":
      return COUNTRY === "us";
    default:
      return false;
  }
};

export enum PLATFORM_TYPE {
  BROKER_PORTAL = "BrokerPortal"
} 