import React from 'react';
import type { SVGProps } from 'react';
import { useSelector } from 'react-redux';
import { customStylesSelector } from 'store/selectors/selectors';

export function LplSvg(props: SVGProps<SVGSVGElement>) {
	const customStyles = useSelector(customStylesSelector);
	return (
		<svg 
			xmlns="http://www.w3.org/2000/svg" 
			width="3.5em" 
			height="3.5em" 
			viewBox="0 0 16 16" 
			fill="none"
			stroke={props.fill ?? customStyles.icons.fill} 
			strokeWidth="0.5" // Adjust thickness here 
			strokeLinecap="round" 
			strokeLinejoin="round" 
			{...props}
		>
			<path d="M14.63 7L13 3h1V2H9V1H8v1H3v1h1L2.38 7H2v1h.15c.156.498.473.93.9 1.23a2.47 2.47 0 0 0 2.9 0A2.44 2.44 0 0 0 6.86 8H7V7h-.45L4.88 3H8v8H6l-.39.18l-2 2.51l.39.81h9l.39-.81l-2-2.51L11 11H9V3h3.13l-1.67 4H10v1h.15a2.48 2.48 0 0 0 4.71 0H15V7zM5.22 8.51a1.5 1.5 0 0 1-.72.19a1.45 1.45 0 0 1-.71-.19A1.5 1.5 0 0 1 3.25 8h2.5a1.5 1.5 0 0 1-.53.51M5.47 7h-2l1-2.4zm5.29 5L12 13.5H5L6.24 12zm1.78-7.38l1 2.4h-2zm.68 3.91a1.4 1.4 0 0 1-.72.19a1.35 1.35 0 0 1-.71-.19a1.55 1.55 0 0 1-.54-.53h2.5a1.37 1.37 0 0 1-.53.53"></path>
		</svg>
	);
}
