import { GppMaybeOutlined, LocalAtm } from "@mui/icons-material";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getDashboardAnalytics } from "api/dashboard";
import SelectComponent from "components/common/SelectComponent";
import { animateRouteProps } from "constants/common";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import { TranslationId } from "translation/types";
import { CurrencyPrefix, formatCurrency } from "utils/utils";
import OutletHeader from "../Common/OutletHeader";
import ChartComponent from "./Charts";
import DashboardFilters from "./DashboardFilters";
import SectionCard from "./SectionCard";

const sections = [
  {
    id: "quotes_submitted",
    title: "QUOTES_SENT",
    subTitle: "COMING_SOON",
    Icon: GppMaybeOutlined,
  },
  {
    id: "policy_issued",
    title: "POLICIES_ISSUED",
    subTitle: "COMING_SOON",
    Icon: GppMaybeOutlined,
  },
  {
    id: "commission_earned",
    title: "COMMISSION_EARNED",
    subTitle: "COMING_SOON",
    Icon: LocalAtm,
  },
];

const defaultDashboardData: DashboardAnalytics = {
  metadata: {
    quotes_submitted: 0,
    policy_issued: 0,
    commission_earned: 0,
  },
  histogram: [
    {
      type: "quotes_submitted",
      data: [],
      labels: [],
    },
    {
      type: "policy_issued",
      data: [],
      labels: [],
    },
    {
      type: "commission_earned",
      data: [],
      labels: [],
    },
  ],
};

const defaultDashboardFilter: FilterParams = {
  start_date: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
  end_date: dayjs().format("YYYY-MM-DD"),
  products: ["PET-MEU"],
};

const Dashboard = () => {
  const { t } = useT();
  const theme = useTheme();
  const customStyles = useSelector(customStylesSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [currentChart, setCurrentChart] = useState("quotes_submitted");
  const [dashboardData, setDashboardData] =
    useState<DashboardAnalytics>(defaultDashboardData);
  const [dashboardFilters, setDashboardFilters] = useState<FilterParams>({
    ...defaultDashboardFilter,
  });

  const options: SelectOption[] = [
    { label: t("QUOTES_SENT"), value: "quotes_submitted" },
    { label: t("POLICIES_ISSUED"), value: "policy_issued" },
    { label: t("COMMISSION_EARNED"), value: "commission_earned" },
  ];

  const fetchDashboardAnalyics = async (payload: FilterParams) => {
    try {
      setLoading(true);
      const response = await getDashboardAnalytics(payload);
      if (response.data.success) {
        setDashboardData(response?.data?.results);
        setError(null);
      } else if (response.data.errorObject) {
        setError(response.data.errorObject?.code);
      }
    } catch (error) {
      setError("Error fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const onDateChange = (newValue: Dayjs, name: string) => {
    setDashboardFilters((f) => ({
      ...f,
      [name]: newValue.format("YYYY-MM-DD"),
    }));
  };

  const onApply = (filterData: Filter[]) => {
    const obj = { ...dashboardFilters };
    filterData.forEach((f) => {
      if (f.options.every((o) => o.isSelected)) {
        if (defaultDashboardFilter[f.id]) {
          obj[f.id] = defaultDashboardFilter[f.id];
        } else {
          delete obj[f.id];
        }
      } else if (f.options.every((o) => !o.isSelected)) {
        obj[f.id] = [];
      } else {
        obj[f.id] = f.options.filter((o) => o.isSelected).map((so) => so.value);
      }
    });
    setDashboardFilters(obj);
  };

  const onClear = (filterData: Filter[]) => {
    setDashboardFilters({ ...defaultDashboardFilter });
  };

  useEffect(() => {
    fetchDashboardAnalyics(dashboardFilters);
  }, [dashboardFilters]);

  const handleSelectChange = (option: SelectOption) => {
    setCurrentChart(option.value as string);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "12px",
      }}
      {...animateRouteProps}
    >
      <OutletHeader
        title={t("DASHBOARD")}
      />
      <DashboardFilters
        onDateChange={onDateChange}
        onApply={onApply}
        onClear={onClear}
      />
      {loading ? (
        <Box sx={{ display: "flex", alignItems: "center", mt: "10%" }}>
          <CircularProgress sx={{ m: "auto" }} />
        </Box>
      ) : error ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "10%",
          }}
        >
          <Typography
            sx={{
              color: customStyles.muiTheme.palette.text.primary,
              fontSize: "24px",
              textAlign: "center",
              fontWeight: 600,
              mb: "16px",
            }}
          >
            {error}
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              mb: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {sections.map((section) => (
              <SectionCard
                title={t(section.title as TranslationId)}
                subTitle={dashboardData?.metadata[section.id]}
                Icon={section.Icon}
                key={section.id}
              />
            ))}
          </Box>
          <Box sx={{ py: "20px" }}>
            <SelectComponent
              options={options}
              defaultOption={options[0]}
              onChange={handleSelectChange}
            />
            <Typography
              sx={{
                fontSize: "16px",
                px: "20px",
                fontWeight: 500,
              }}
            >
              {currentChart === "commission_earned"
                ? `${CurrencyPrefix}
              ${formatCurrency(dashboardData?.metadata[currentChart])}`
                : dashboardData?.metadata[currentChart]}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "12px",
              p: "20px",
            }}
          >
            <ChartComponent
              data={dashboardData?.histogram.find(
                (h) => h.type === currentChart
              )}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Dashboard;
